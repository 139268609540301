import {
  community,
  country,
  members,
  post,
  speciality,
  subspeciality,
} from "../../js/path";
import _ from "lodash";

export default {
  data() {
    return {
      isPostUrl: true,
      isImageVideo: true,
      removeItem: null,
      showFileName: false,
      postTypes: [
        {
          text: "Post URL Link",
          value: "post_url_link",
        },
        {
          text: "Images/Videos",
          value: "post_images_videos",
        },
      ],
      settings: {
        autoplay: true,
        centerMode: true,
        centerPadding: "20px",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
      },
      slide: 0,
      interval: 0,
      table_header: [],
      activeTab: "all",
      post: [],
      currentPage: 1,
      params: "",
      key: 0,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      pageNo: null,
      noData: false,
      allCountry: [],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "title",
          label: "Post Title",
          class: "w-400",
        },
        {
          key: "action_type",
          label: "Post Type",
          class: "text-center",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "doctor_approval",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      form: {
        action_type: "post_an_update",
        title: "",
        description: "",
        media_type: "",
        media: [],
        country: [],
        sub_specialities: [],
        member_tagging: null,
        meta_title: "",
        meta_desc: "",
        meta_keywords: "",
        communities_selected: [],
        post_meta_title: "",
        post_meta_desc: "",
        post_meta_image: "",
        slug: "",
        is_ppt: false,
        url_link: "",
        visible_on_doctor_profile: 0
      },
      getCommunity: [],
      subspecialities: [],
      memberList: [],
      images_videos: [],
      file_name: "",
      edit: {
        file_name_url: null,
      },
      urlIsValid: true,
      member_speciality: "",
      post_slug: null,
    };
  },
  methods: {
    isEmpty(value) {
      return Array.isArray(value) && value.length === 0;
    },
    slugify() {
      this.post_slug = this.form.title;
      if (this.post_slug) {
        const slug_generate = this.post_slug
          .toString()
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^\w-]+/g, "")
          .replace(/--+/g, "-")
          .replace(/^-+/, "")
          .replace(/-+$/, "");
        this.form.slug = slug_generate;
      } else {
        this.form.slug = null;
      }
    },
    changePostUrl() {
      this.form.post_meta_title = "";
      this.form.post_meta_desc = "";
      this.form.post_meta_image = "";
      this.removeItem = null;
    },
    async fetchMetadata() {
      this.$store.commit("loader/updateStatus", true);
      document.getElementById("fetch-url").style.border = "";
      const formattedURL = this.form.url_link;
      console.log(formattedURL, "url");
      try {
        const response = await this.getRequest(
          `${this.$hostLink}/api/metaTags?url=${formattedURL}`
        );
        if ("title" in response.metadata) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Post URL fetched successfully",
          });
          const title = response.metadata.title;
          const responseData = response.metadata;
          this.form.post_meta_title = responseData.title;
          if (responseData.description) {
            this.form.post_meta_desc = responseData.description;
          }
          if (responseData.image) {
            console.log(responseData.image);
            this.form.post_meta_image = responseData.image;
          }
          console.log(`Title: ${title}`);
        } else {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Invalid Post URL",
          });
          console.error("Error: Title key is missing in the metadata object.");
        }
      } catch (err) {
        console.log(err, "inside log");
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Invalid Post URL",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    setSlide() {
      this.slide = this.slide + 1;
    },
    clearImageVideo() {
      this.images_videos = [];
      this.removeItem = null;
    },
    async deleteImageVideo(itemId, index, type) {
      let res = await this.$bvModal.msgBoxConfirm(
        "Are you sure you want to remove this item ?",
        {
          title: "Confirm deletion",
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          okTitle: "Yes",
          headerClass: "px-4 pt-4 pb-2 border-bottom-0",
          bodyClass: "px-4 py-2 fs-5",
          footerClass: "px-4 pb-4 pt-2 border-top-0",
          centered: true,
        }
      );
      if (res == true) {
        this.$store.commit("loader/updateStatus", true);
        const url = post.deletePostImageVideo;
        const data = await this.postRequest(url + "/" + itemId, {
          _method: "DELETE",
        });
        if (data.status) {
          let temp = this.images_videos;
          temp.splice(index, 1);
          console.log(this.images_videos, "this.images_videos");
          this.images_videos = temp;
          if (type == "video") {
            location.reload();
          }
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$store.commit("loader/updateStatus", false);
        }
      }
    },
    async fetchAllCountry(query) {
      const url = country.countryUrl;
      const data = await this.getRequest(url + "?search=" + query);
      if (data.status) {
        this.allCountry = data.response.data;
      }
    },
    async fetchSingleCountry(name) {
      const url = country.countryUrl;
      const data = await this.getRequest(url + "/" + name + "?country_name=1");
      if (data.status) {
        this.form.country.push(data.response);
      }
    },
    async fetchCommunity(query) {
      const url = community.fetchAllActiveCommunity;
      const data = await this.getRequest(url + "&search=" + query);
      if (data.status) {
        this.getCommunity = data.response.data;
      }
    },
    async fetchSubSpecialities() {
      const url = subspeciality.fetchSubSpeciality;
      const data = await this.getRequest(url + "?nopagination");
      if (data.status) {
        this.subspecialities = data.response.data;
        console.log(this.subspecialities, "fetch");
      }
    },
    fetchMemberList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.memberList = [];
      if (query != "" && query != null) {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getMembers;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.memberList = data.response.data.map(
            (member) =>
              (this.memberList = {
                member_id: member.id,
                fnameAndLname: `${member.fname} ${member.lname} - ${member.mobile_number}`,
                city: member.city,
                profile_image: member.profile_image,
                speciality_id: member.speciality_id,
              })
          );
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),
    async fetchSingleMember(id) {
      const url = members.getMembers;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form.member_tagging = {
          member_id: data.response.id,
          fnameAndLname: `${data.response.fname} ${data.response.lname} - ${data.response.mobile_number}`,
          member_speciality_id: data.response.speciality_id ?? "",
          city: data.response.city ?? "",
          profile_image: data.response.profile_image ?? "",
        };
      }
    },
    async fetchSingleSpeciality(id) {
      const url = speciality.specialityUrl;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.member_speciality = data.response.title;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.currentPage = 1;
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      }
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      } else {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    readFile(e, txt) {
      if (txt == "image_video") {
        const files = e.target.files;
        const MAX_FILE_SIZE = 20480;
        for (let i = 0; i < files.length; i++) {
          const fileSizeInKB = files[i].size / 1024;
          if (fileSizeInKB > MAX_FILE_SIZE) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Item cannot be larger than 20 MB",
            });
            e.target.value = null;
            return;
          }
          this.images_videos.push({
            item_id: "",
            media_type: files[i].type.includes("image")
              ? "image"
              : files[i].type.includes("video")
              ? "video"
              : "",
            image_name: URL.createObjectURL(files[i]),
            file: files[i],
          });
        }
      } else if (txt == "file_name") {
        this.showFileName = false;
        this.file_name = e.target.files[0];
        const MAX_FILE_SIZE = 20480;
        const fileSizeInKB = this.file_name.size / 1024;
        if (fileSizeInKB > MAX_FILE_SIZE) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "File cannot be larger than 20 MB",
          });
          this.$refs.case_ppt.reset();
        }
      }
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.post.data.findIndex((e) => e.id === id);
        const url = post.statusUpdate;
        const data = await this.postRequest(url, {
          is_active: !this.post.data[index].is_active,
          id: id,
        });
        if (data.status) {
          this.post.data[index].is_active = !this.post.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    tabActive() {
      if (this.activeTab == "trash") {
        if (this.table_header.includes("delete")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          this.fields[index] = {
            key: "restore",
          };
          let table_index = this.table_header.findIndex(
            (item) => item == "delete"
          );
          this.table_header[table_index] = "restore";
        } else {
          this.table_header.push("restore");
          this.fields.push({
            key: "restore",
          });
        }

        if (!this.can("restore-post-an-update")) {
          let index = this.fields.findIndex((item) => item.key == "restore");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header.splice(table_index, 1);
          }
        }

        let index = this.fields.findIndex((item) => item.key == "edit");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "edit"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (this.table_header.includes("restore")) {
          if (!this.can("edit-post-an-update")) {
            this.fields[4] = {
              key: "delete",
            };
          } else {
            this.fields[5] = {
              key: "delete",
            };
          }

          let table_index = this.table_header.findIndex(
            (item) => item == "restore"
          );
          this.table_header[table_index] = "delete";
        }

        if (!this.can("edit-post-an-update")) {
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("edit")) {
            this.table_header.push("edit");
            this.fields[4] = {
              key: "edit",
            };
          }
        }

        if (!this.can("delete-post-an-update")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("delete")) {
            this.table_header.push("delete");
            this.fields[5] = {
              key: "delete",
            };
          }
        }
      }
    },
    async fetchData(txt) {
      let url = null;
      if (txt == "trash") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = post.getAllPosts + "?filter=" + txt + "&search=" + this.filter;
        } else if (this.filter != null && this.filter != "") {
          url = url = post.getAllPosts + "?filter=" + txt + this.params;
        } else {
          url = post.getAllPosts + "?filter=" + txt;
        }
      } else if (txt == "active") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = post.getAllPosts + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = post.getAllPosts + "?filter=" + txt;
        }
      } else if (txt == "all") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = post.getAllPosts + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = post.getAllPosts + "?filter=" + txt;
        }
      }
      url += this.params;
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          this.post = data.response;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    async doctorApproval(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = post.doctorApproval;
        const data = await this.postRequest(url, {
          action_id: id,
        });
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
        this.fetchData(this.activeTab);
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchPost(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = post.getAllPosts;
        const data = await this.getRequest(`${url}/${id}`);
        if (data.status) {
          const responseData = data.response;
          if (responseData.is_ppt) {
            this.form.is_ppt = responseData.is_ppt;
          }
          if (
            responseData.action_type != null &&
            responseData.action_type != ""
          ) {
            this.form.action_type = responseData.action_type;
          }
          if (responseData.sub_specialities.length > 0) {
            this.form.sub_specialities = this.subspecialities.filter((item) => {
              if (responseData.sub_specialities.includes(item.id)) {
                return item;
              }
            });
          }
          if (responseData.title != null && responseData.title != "") {
            this.form.title = responseData.title;
          }
          if (responseData.url_link != null && responseData.url_link != "") {
            this.isPostUrl = true;
            this.form.url_link = responseData.url_link;
          } else {
            this.isPostUrl = false;
          }
          if (responseData.slug != null && responseData.slug != "") {
            this.form.slug = responseData.slug;
          }
          if (
            responseData.description != null &&
            responseData.description != ""
          ) {
            this.form.description = responseData.description;
          }
          if (
            responseData.media_type != null &&
            responseData.media_type != ""
          ) {
            this.form.media_type = responseData.media_type;
          }
          if (
            responseData.meta_title != null &&
            responseData.meta_title != ""
          ) {
            this.form.meta_title = responseData.meta_title;
          }
          if (
            responseData.meta_description != null &&
            responseData.meta_description != ""
          ) {
            this.form.meta_desc = responseData.meta_description;
          }
          if (
            responseData.meta_keywords != null &&
            responseData.meta_keywords != ""
          ) {
            this.form.meta_keywords = responseData.meta_keywords;
          }
          if (
            responseData.post_meta_title != null &&
            responseData.post_meta_title != ""
          ) {
            this.form.post_meta_title = responseData.post_meta_title;
          }
          if (
            responseData.post_meta_description != null &&
            responseData.post_meta_description != ""
          ) {
            this.form.post_meta_desc = responseData.post_meta_description;
          }
          if (
            responseData.post_meta_image != null &&
            responseData.post_meta_image != ""
          ) {
            this.form.post_meta_image = responseData.post_meta_image;
          }
          if (
            responseData.meta_title != null &&
            responseData.meta_title != ""
          ) {
            this.form.meta_title = responseData.meta_title;
          }
          if (responseData.visible_on_doctor_profile) {
            this.form.visible_on_doctor_profile = true;
          }
          if (responseData.community_maps.length > 0) {
            this.form.communities_selected = responseData.community_maps.map(
              (c) => {
                return {
                  id: c.id,
                  title: c.title,
                };
              }
            );
          }
          if (responseData.images.length > 0) {
            this.isImageVideo = true;
            responseData.images.map((item) => {
              console.log(item, "item");
              this.images_videos.push({
                item_id: item.item_id ?? "",
                image_name: item.image_name ?? "",
                media_type: item.media_type ?? "",
              });
            });
          } else {
            this.isImageVideo = false;
          }
          if (responseData.country != null && responseData.country != "") {
            responseData.country.map((item) => {
              this.fetchSingleCountry(item);
            });
          }
          if (responseData.created_by) {
            console.log(responseData.created_by, "responseData.created_by");
            this.form.member_tagging = this.fetchSingleMember(
              responseData.created_by
            );
          }
          if (responseData.is_ppt) {
            this.form.is_ppt = true;
          } else {
            this.form.is_ppt = false;
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    validateURL: _.debounce(async function () {
      if (this.form.url_link) {
        const urlPattern = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})(\/\S*)?$/;
        this.urlIsValid = urlPattern.test(this.form.url_link);
        console.log(this.urlIsValid, "test");
        if (this.urlIsValid) {
          this.getURL();
        } else {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Enter valid URL Link",
          });
        }
      }
    }, 2000),
    async submitData() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = post.getAllPosts;
        if (this.$route.name == "edit-post") {
          url = post.getAllPosts + "/" + this.$route.params.id;
        }
        let dataAppend = new FormData();
        if (this.form.communities_selected) {
          const communities_selected = [];
          this.form.communities_selected.map((key) => {
            communities_selected.push(key.id);
          });
          dataAppend.append(
            "communities_selected",
            JSON.stringify(communities_selected)
          );
        }
        if (this.images_videos.length > 0) {
          for (let index = 0; index < this.images_videos.length; index++) {
            if (this.images_videos[index].file) {
              dataAppend.append(
                "media[" + index + "]",
                this.images_videos[index].file
              );
            }
          }
        }
        if (this.form.country) {
          const country = this.form.country.map((key) => key.name);
          country.forEach((l) => dataAppend.append("country[]", l));
        }
        if (this.form.sub_specialities) {
          const sub_specialities = this.form.sub_specialities.map(
            (key) => key.id
          );
          sub_specialities.forEach((s) =>
            dataAppend.append("sub_specialities[]", s)
          );
        }
        if (this.file_name && this.form.action_type == "presentation") {
          dataAppend.append("ppt_media", this.file_name);
        }
        if (this.form.post_meta_title && this.form.post_meta_desc) {
          dataAppend.append("post_meta_title", this.form.post_meta_title);
          dataAppend.append("post_meta_desc", this.form.post_meta_desc);
        }
        if (this.form.visible_on_doctor_profile) {
            dataAppend.append("visible_on_doctor_profile", 1);
        } else {
            dataAppend.append("visible_on_doctor_profile", 0);
        }
        if (this.form.member_tagging) {
          dataAppend.append(
            "member_tagging",
            this.form.member_tagging.member_id
          );
        }
        if (this.form.url_link) {
          dataAppend.append("url_link", this.form.url_link);
        } else {
          dataAppend.append("url_link", "");
        }
        for (var key in this.form) {
          if (
            key != "communities_selected" &&
            key != "media" &&
            key != "country" &&
            key != "ppt_media" &&
            key != "sub_specialities" &&
            key != "media_type" &&
            key != "post_meta_title" &&
            key != "post_meta_desc" &&
            key != "member_tagging" &&
            key != "url_link" &&
            key != "visible_on_doctor_profile"
          ) {
            dataAppend.append(key, this.form[key]);
          }
        }
        if (this.$route.name == "edit-post") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/post");
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deletePost(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.post.data.findIndex((e) => e.id === id);
        const url = post.getAllPosts + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.post.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restorePost(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.post.data.findIndex((e) => e.id === id);
        const url = post.getAllPosts + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.post.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    changeActionType() {
      this.form.title = "";
      this.form.description = "";
      this.form.country = [];
      this.form.sub_specialities = [];
      this.form.communities_selected = [];
      this.form.meta_title = "";
      this.form.meta_desc = "";
      this.form.meta_keywords = "";
      this.form.member_tagging = null;
      this.form.slug = "";
      this.form.post_meta_title = "";
      this.form.post_meta_desc = "";
      this.form.post_meta_image = "";
      this.images_videos = [];
      this.file_name = "";
      this.form.url_link = "";
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
    "form.member_tagging"(v) {
      if (v) {
        if (v.speciality_id) {
          const speciality_id = v.speciality_id;
          this.fetchSingleSpeciality(speciality_id);
        }
      }
    },
    "form.action_type"(v) {
      if (v == "presentation") {
        this.showFileName = true;
      }
    },
  },
  mounted() {
    if (this.$route.name == "post") {
      this.fetchData(this.activeTab);
    } else if (this.$route.name == "edit-post") {
      this.fetchPost(this.$route.params.id);
    }
    if (this.$route.name == "add-post" || this.$route.name == "edit-post") {
      this.fetchSubSpecialities();
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
  },
  computed: {
    actionType() {
      if (this.$route.name == "add-post") {
        return [
          {
            text: "Post an Update",
            value: "post_an_update",
          },
          {
            text: "Presentation",
            value: "presentation",
          },
        ];
      }
      if (this.form.action_type == "post_an_update") {
        return [
          {
            text: "Post an Update",
            value: "post_an_update",
          },
        ];
      } else {
        return [
          {
            text: "Presentation",
            value: "presentation",
          },
        ];
      }
    },
  },
};
